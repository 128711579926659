.wrapper {
  padding: 0px 25px;
}

.noBoards {
  padding: 0px 25px;
}

.recentTitle {
  padding: 10px 25px;
  font-weight: 500;
}
