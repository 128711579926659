.wrapper {
  border-radius: 1px;
  padding: 10px 10px 10px 25px;
  display: grid;
  grid-template-columns: minmax(0, 1.5fr) minmax(0, 1fr) minmax(160px, auto);
  gap: 30px;
  align-items: center;
  justify-content: space-between;
}

.wrapper > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper:hover {
  background: #e6f1ff;
  cursor: pointer;
}

.boardName {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail {
  color: #848199;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  text-align: right;
  justify-self: end;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumbs {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-self: end;
  max-width: 100%;
}

.breadcrumb {
  color: #848199;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 35px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
