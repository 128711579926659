.wrapper {
  height: 35px;
  background: #f5f7fa;
  color: #848199;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 300;
  border-radius: 2px;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
  cursor: pointer;
}

.wrapper:hover {
  background: #ebedf3;
}

.selected {
  background: #ebedf3;
}
