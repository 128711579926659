.container {
  position: fixed;
  bottom: 26px;
  left: 226px;
  right: 0;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #113357;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  animation: slide-up 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  pointer-events: auto; /*TODO: fix cursor on close icon */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  padding: 10px 0;
}

.close {
  margin-left: 6px;
  margin-right: 10px;
  pointer-events: auto;
  cursor: pointer;
}

@keyframes slide-up {
  0% {
    bottom: 0px;
    transform: translateY(100%);
  }
  100% {
    bottom: 14px;
    transform: translateY(0);
  }
}
