.initialsIcon {
  width: 22px;
  height: 22px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
