.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 509px;
  height: 270px;
  background: #ffffff;
  box-shadow: 0px 7.59259px 25.3086px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWrapper {
  composes: flexContainer;
  gap: 62px;
}

.content {
  composes: flexContainer;
  gap: 12px;
}

.modalTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #0b2642;
}

.modalText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.202781px;
  color: #0b2642;
}

.button {
  width: 261px;
  height: 35.54px;
  background: #1973ff;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15.6365px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.124179px;
  color: #ffffff;
  border: none;
}

.button:hover {
  opacity: 0.5;
}
