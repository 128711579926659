.noCanvases {
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-line;
  gap: 14px;
  padding: 90px 20px;
}

.noCanvases img {
  width: 114px;
  height: 96px;
}

.noCanvases > span {
  color: #113357;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.129px;
}
