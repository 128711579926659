.gridContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 9px;
  max-width: 146px;
}

.gridContainer:nth-child(-n + 1) {
  display: -ms-flexbox;
  display: flex;
}

.gridContainer {
  display: flex;
  transition: width 0.5s ease-in-out;
}

.gridContainer:last-child {
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 620px) {
  .gridContainer:last-child {
    display: none;
  }
}

.gridContainer.small {
  min-width: 160px;
  max-width: 160px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.proBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 30px;
  background-color: rgba(245, 181, 61, 0.2);
}

.templateTitle {
  color: #113357;
  font-family: "Sora";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.646px;
  letter-spacing: 0.122px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.templateImage {
  position: relative;
  background: white;
  border: 0.5px solid #dadce0;
  transition: all 100ms;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 6px 0 rgba(17, 51, 87, 0.15);
  cursor: pointer;
}

.menuIcon {
  padding: 46px 52px;
}

.imageContainer {
  width: 133px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer > img {
  padding: 10px;
  max-width: 100%;
  max-height: 100%;
}

.templateImage.small:hover {
  box-shadow: 0px 24px 48px -8px #0b264233;
}

.buttons {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
}

.buttons:hover {
  opacity: 1;
}
