.noSearchResults {
  height: 244px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noSearchResults > img {
  width: 115.178px;
  height: 100px;
}

.noSearchResults .title {
  color: #113357;
  text-align: center;
  font-family: "Sora";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40.041px;
  letter-spacing: -0.132px;
}

.noSearchResults span {
  color: #113357;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
}

.noSearchResults .searchTerm {
  color: #113357;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
