.section {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 30px;
  margin: 0px 37px;
  padding: 17px 22px;
  background-color: #f4f7fa;
}

.titleLine {
  color: #11355e;
  font-family: "Sora";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.213px;
}

.subtitleLine {
  margin-top: 9px;
  color: #11355e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.307px;
  letter-spacing: 0.272px;
}

@media screen and (max-width: 620px) {
  .subtitleLine {
    display: none;
  }
}

.templateContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 22px;
  transform: translateZ(1px);
}

@media (max-width: 730px) {
  .recommendedTemplates {
    display: none;
  }
}

.grid {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-around;
  transition: width 0.5s ease-in-out;
}

.unlockCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 8px rgba(11, 38, 66, 0.15);
  height: 118px;
  width: 346px;
  padding: 18px;
  cursor: pointer;
}

.unlockContainer {
  display: flex;
}

.unlockButton {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  height: 32px;
  border-radius: 2px;
  background: var(--blue);
}

.unlockButton:hover {
  cursor: pointer;
  background: var(--dark-blue);
}

.unlockButton span {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}
