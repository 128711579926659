.option {
  height: 48px;
  line-height: 48px;
  font-size: 15px;
  padding: 0px 18px;
  font-weight: 300;
  border-radius: 2px;
  cursor: pointer;
}

.option:hover,
.option[data-isselected="true"] {
  background: #e6f1ff;
}
