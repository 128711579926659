.skeleton {
  width: 133px;
  height: 96px;
  margin-bottom: 20px;
}

.skeletonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
