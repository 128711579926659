.title {
  display: inline-flex;
  position: relative;
  display: inline-flex;
  padding-top: 3.5px;
}

.title span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title span,
.title img {
  pointer-events: none;
}

.title span {
  position: relative;
}

.title span,
.titleInput {
  font-family: Poppins, Roboto, Arial;
  font-weight: 500;
  text-align: start;
  color: #0b2642;
}

.titleInput {
  width: auto;
}

.title.textStyleRegular span,
.titleInput.textStyleRegular {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
  height: 18px;
  text-align: left;
  padding-right: 15px;
}

.textStyleBold.title span,
.textStyleBold .titleInput {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  height: 18px;
  text-align: left;
  padding-right: 15px;
}

.titleInput,
.title.editable:hover {
  border: none;
  cursor: pointer;
}

.editable .inputBorder.show {
  background-color: white;
}

.titleInput:focus {
  outline: none;
  transform: translate(-2px, -1px);
  width: 100%;
  background-color: transparent;
}

.editTitleIcon {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.title.editable:hover .editTitleIcon {
  opacity: 1;
}

.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
