.searchContainer {
  width: 590px;
  height: 360px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(218, 220, 224);
  box-shadow: rgba(11, 38, 66, 0.15) 0px 3px 8px 0px;
  padding: 5px 5px 0px 5px;

  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
