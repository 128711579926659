.list {
  padding: 10px 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.cell {
  position: relative;
  padding: 3px 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.cell:hover {
  background: #f4f7fa;
  cursor: pointer;
}

.index {
  font: 300 14px "Poppins";
  width: 10px;
}

.menuIcon {
  position: absolute;
  right: 8px;
  width: 15px;
  height: 15px;
  background: #848199;
  -webkit-mask: url(/images/menu-icon.svg) no-repeat center;
  mask: url(/images/menu-icon.svg) no-repeat center;
  mask-size: contain;
}

.menuIcon:hover {
  background: #1973ff;
}

.hiddenFrameText {
  height: 20px;
  display: flex;
  gap: 8px;
  align-content: center;
  padding: 4px;
}

.hiddenFrameText span {
  font-family: Poppins, Roboto, Arial;
  text-align: center;
  color: #797e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.framesEmptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 42px;
  height: 82%;
  gap: 20px;
}

.framesEmptyState img {
  width: 110px;
}

.framesEmptyState p {
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

.selectedProject {
  background: #e6f1ff;
}

.teamMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 140px;
  height: fit-content;
  border: 1px solid #ebedf3;
  background: #fff;
  padding: 10px;
}

.item {
  width: 100%;
  padding: 0 2px;
  color: #11355e;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 25.612px;
}

.item:hover {
  cursor: pointer;
  background: #e6f1ff;
}

.delete {
  color: #e03e1a;
}

.arrow {
  background: #ffffff;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  left: 65px;
  top: -6px;
  border-left: 1px solid #ebedf3;
  border-top: 1px solid #ebedf3;
}

.rename {
  background-color: #fff;
  border: 1px solid #1973ff;
}

.teamName {
  display: flex;
  align-items: center;
}
