.container {
  width: 517px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px;
  position: relative;
}

.ownerButtons {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.ownerButtons:hover {
  cursor: pointer;
}

.cancelButton {
  all: unset;
  background: #f4f7fa;
  color: #113357;
  text-align: center;
  font-family: "Sora";
  font-size: 14px;
  font-weight: 400;
  width: 78px;
  height: 32px;
}

.cancelButton:hover {
  cursor: pointer;
  opacity: 0.5;
}
