.container {
  width: 517px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.header {
  margin-top: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 15px 20px 0 15px;
}

.title {
  font-family: "Sora", "Poppins", serif;
  font-size: 16px;
  font-weight: 700;
  color: #113357;
}

.manageSeats {
  font-family: "Poppins", serif;
  font-size: 12px;
  font-weight: 400;
  color: #1973ff;
}

.manageSeats:hover {
  cursor: pointer;
  opacity: 0.5;
}

.collaborators {
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 20px;
  max-height: 200px;
  border-top: 1px solid #ebedf3;
  border-bottom: 1px solid #ebedf3;
  margin: 10px 0;
}

.boardsContainer {
  height: 302px;
  overflow-y: auto;
}

.boards {
  padding: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 16px;
  justify-content: flex-start;
  width: 515px;
}

.boardImage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background: white;
  border: 0.5px solid #dadce0;
  transition: all 100ms;
  width: 104.238px;
  height: 76.393px;
}

.boardImage:hover {
  cursor: pointer;
  box-shadow: 0px 24px 48px -8px #0b264233;
}

.boardImage > input:hover {
  cursor: pointer;
}

.link {
  color: #1973ff;
  white-space: nowrap;
  text-align: right;
  font-size: 12px;
  font-family: Poppins;
  line-height: 13.165px;
  letter-spacing: 0.219px;
}

.link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.boardName {
  color: #113357;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.646px;
  letter-spacing: 0.122px;
  max-width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 8px;
}

.searchSection {
  display: flex;
  flex-direction: row;
  gap: 11px;
  align-items: center;
  padding: 0 25px 25px 25px;
}

.selectAll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}

.selectAll:hover {
  cursor: pointer;
  opacity: 0.5;
}

.selectAll > input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: unset;
  color: #113357;
  width: 15px;
  height: 15px;
  border: 1px solid #113357;
}

.selectAll > input[type="checkbox"]:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: unset;
  color: #113357;
  width: 15px;
  height: 15px;
  border: 1px solid transparent;
}

.selectAll > input[type="checkbox"]::before {
  content: "";
  display: block;
  width: 13px;
  padding: 1px;
}

.selectAll > input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode for checkmark (V) */
  color: #fff;
  background: #007bff;
  text-align: center;
  line-height: 13px;
  font-weight: 500;
}

.chooseAllText {
  color: #113357;
  font-family: "Sora";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.213px;
}

.searchContainer {
  background-image: url("/images/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 14px;
  display: flex;
  border: 0.5px solid #dadce0;
  height: 36px;
  width: 100%;
  padding: 0 32px;
}

.searchContainer input {
  border: 0;
  outline: none;
  height: 34px;
  flex-grow: 1;
  font-size: 12px;
  color: #113357;
}

.searchContainer::before {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.66669 10.3334C8.24404 10.3334 10.3334 8.24404 10.3334 5.66669C10.3334 3.08935 8.24404 1 5.66669 1C3.08935 1 1 3.08935 1 5.66669C1 8.24404 3.08935 10.3334 5.66669 10.3334Z' stroke='%23848199' stroke-linecap='round' strokeLinejoin='round'/><path d='M13.001 13L9.00098 9' stroke='%23848199' stroke-linecap='round' strokeLinejoin='round'/></svg>");
  background-repeat: no-repeat;
  background-position: 5px center; /* Adjust the position as needed */
  display: inline-block;
  width: 20px; /* Adjust the width of the SVG icon */
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.divider {
  width: 100%;
  height: 1px;
  background: #dadce0;
  padding: 0 20px;
  align-self: center;
}

.boardImage > input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: unset;
  color: #113357;
  width: 15px;
  height: 15px;
  border: 1px solid #113357;
}

.boardImage > input[type="checkbox"]:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: unset;
  color: #113357;
  width: 15px;
  height: 15px;
  border: 1px solid transparent;
}

.boardImage > input[type="checkbox"]::before {
  content: "";
  display: block;
  width: 13px;
  padding: 1px;
}

.boardImage > input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode for checkmark (V) */
  color: #fff;
  background: #007bff;
  text-align: center;
  line-height: 13px;
  font-weight: 500;
}
