.container {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  pointer-events: visible;

  border-radius: 20px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.200691));
  background-color: white;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 18px;
  height: 18px;
}

.close:hover {
  cursor: pointer;
  opacity: 0.5;
}

.container svg {
  width: 13px !important;
  height: 13px !important;
}
