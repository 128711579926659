.container {
  width: 517px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 25px;
  position: relative;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.title {
  color: #113357;
  font-family: "Sora";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.098px;
  margin-bottom: 25px;
}

.subtitle {
  color: #0b2642;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.213px;
  margin-bottom: 25px;
}

.inviteField {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.teamName {
  all: unset;
  border: 1px solid #dadce0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  padding: 10px;
}

.leaveButtons {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.leaveButtons:hover {
  cursor: pointer;
}

.closeIcon {
  cursor: pointer;
  width: 14px;
  height: 14px;
  align-self: flex-end;
  position: absolute;
  top: 12px;
  right: 12px;
  color: #848199;
}
.closeIcon:hover {
  color: #c2c3c9;
  cursor: pointer;
}

.extraPaddingTop {
  padding-top: 25px;
}
