.searchbar {
  display: flex;
  gap: 6px;
  align-items: center;
  border: 1px solid #dadce0;
  padding: 7px 20px;
  border-radius: 2px;
  width: 590px;
  height: 42px;
}

.searchbar[data-isopen="true"] {
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.searchbar > input {
  width: -webkit-fill-available;
  height: 30px;
  border: none;
  outline: none;

  font-family: Poppins;
  font-size: 15px;
}

.searchbar > input::placeholder {
  color: #afb3bf;
}

.filter {
  background: #e6f1ff;
  border-radius: 2px;
  white-space: nowrap;
  font-weight: 300;
  padding: 5px 12px 5px 16px;
  display: flex;
  align-items: center;
  color: #113357;
  font-size: 15px;
  gap: 12px;
}
