.wrapper {
  gap: 7px;
  display: grid;
  grid-template-columns: 112px 112px;
  padding: 10px;
}

.filterOption {
  color: #848199;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  height: 42px;
  line-height: 42px;
  padding: 0px 8px;
}

.filterOption:hover {
  background: #e6f1ff;
}
.filterOption:hover > input {
  background: #e6f1ff;
}
