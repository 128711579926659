.container {
  background-color: white;
  height: 160px;
  border: 1px solid #e6e9ef;
  box-shadow: 0px 1px 8px 0px #0b264226;
  transition: all 100ms;
  max-width: 224px;
}

.container:hover {
  filter: none;
  box-shadow: 0px 24px 48px -8px #0b264233;
  /* transform: scale3d(1.01, 1.01, 1.01); */
}
