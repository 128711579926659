.wrapper {
  background-color: white;
  box-shadow: 0px 0px 10px 0px #0000001a;
  padding: 5px;
  color: #848199;
  display: grid;
  grid-template-columns: 170px 1fr;
}

.line {
  width: 1px;
  height: 100%;
  background: #d9d9d9;
}

.customDate {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 13px;
  padding: 5px;
}
