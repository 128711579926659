.inviteField {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nameInput {
  all: unset;
  border: 1px solid #dadce0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  padding: 10px;
  color: #113357;
}

.nameInput > input {
  all: unset;
  width: 100%;
}

.leaveButtons {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.leaveButtons:hover {
  cursor: pointer;
}

.closeIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  align-self: flex-end;
}
